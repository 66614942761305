import { enableProdMode } from '@angular/core';
import '@angular/localize/init';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@mzic/mzic-environments';
import posthog from 'posthog-js';
import { AppModule } from './app/app.module';

posthog.init(environment.posthogKeyProjectArtists, {
  api_host: environment.posthogHost,
  person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
  capture_pageview: false,
});

if (environment.production) {
  enableProdMode();
}
console.log(environment.apiUrl);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
